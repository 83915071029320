.pad0 {
  padding: 0;
}

.pad1 {
  padding: 0.5rem;
}

.pad2 {
  padding: 0.75rem 1rem;
}

.pad3 {
  padding: 1.6rem 2rem;
}

.onlyIconPad0 {
  padding: 0;
}

.onlyIconPad1 {
  padding: 0.5rem;
}

.onlyIconPad2 {
  padding: 0.75rem;
}

.onlyIconPad3 {
  padding: 1.6rem;
}

.base svg + div {
  margin-left: 0.5rem;
}

.base svg {}

.filled {
  border-width: 1px;
  &:hover {
    box-shadow: inset 0 0 0 1px var(--white);
  }
}

.base:focus {
  box-shadow: inset 0 0 0 1px var(--white), 0 0 0 1px var(--primary);
}

.secondaryFocus:focus {
  box-shadow: inset 0 0 0 1px var(--white), 0 0 0 1px var(--secondary);
}

.group {
  display: flex;
}

.group button {
  margin-left: 2px;

  & .br-big {
    border-radius: 4px;
  }

  & .br-small {
    border-radius: 2px;
  }

  &:not(:first-of-type):not(:last-of-type) {
    border-radius: 0;
  }

  &:first-of-type {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-left: 0;
    margin-right: -1px;
  }

  &:last-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.group:first-child {
  margin-left: 0;
}
