/*
  TODO:
  - Intellisense support somehow
  - Take care of stray colors
  - Find rgb and rgba stray colors
*/

/*
  --------------------------------------------------------------
  usage in css:
  .my-class {
    color: var(--primary);
  }

  usage in js:
  import colors from '../path/to/colors/colors'
  <div style={{ color: colors.primary }} />
  --------------------------------------------------------------
*/

:root {
  /* Stray color values. Take care of these! */
  --todo_2f2f2f: #2f2f2f;
  --todo_333: #333;
  --todo_666: #666;
  --todo_525252: #525252;
  --todo_979797: #979797;
  --todo_8a8a8a: #8a8a8a;
  --todo_8c8c8c: #8c8c8c;
  --todo_ddd: #ddd;
  --todo_eee: #eee;
  --todo_aaa: #aaa;
  --todo_f6f6f6: #f6f6f6;
  --todo_A9A9A9: #A9A9A9;
  --todo_F1F1F1: #F1F1F1;
  --todo_f5f5f5: #f5f5f5;
  --todo_f9f9f9: #f9f9f9;
  --todo_f7f7f7: #f7f7f7;
  --todo_0051BA: #0051BA;
  --todo_007BFF: #007BFF;
  --todo_rgb255_13_25: rgb(255, 13, 25);
  /* ---------------- */

  --primary: #FCDA32;
  --primaryLight: #FDE884;
  --primaryLighter: #FEF7D6;
  --primaryTransparent:  rgba(252, 218, 50, 0.5);

  --secondary: #225CBB;
  --secondaryLight: #548EDB;
  --secondaryLighter: #D2DEF1;

  --error: #C71110;
  --errorLightAlt: #bf5758;
  --errorLight: #e79696;
  --errorLighter: #f7dcdc;

  --warn: #E69E5E;
  --warnLighter: #F3C195;

  --success: #2CBA03;
  --successDark: #376f26;

  --gray: #B3B3B3;
  --grayAlt: #808080;
  --grayDark: #222;
  --grayLight: #E5E5E5;
  --grayLightAlt: #E1E1E1;
  --grayLighter: #fbfcfc;
  --grayAccessible: #595959;
  --grayBlueDark: #282C35;
  --grayBlueLight: #2d3340;

  --white: #fff;
  --white10: rgba(255,255,255,0.1);
  --white25: rgba(255,255,255,0.25);
  --white40: rgba(255,255,255,0.4);
  --white55: rgba(255,255,255,0.55);
  --white70: rgba(255,255,255,0.7);
  --white85: rgba(255,255,255,0.85);

  --black: #000;
  --black10: rgba(0,0,0,0.1);
  --black25: rgba(0,0,0,0.25);
  --black40: rgba(0,0,0,0.4);
  --black55: rgba(0,0,0,0.55);
  --black70: rgba(0,0,0,0.7);
  --black85: rgba(0,0,0,0.85);
}
