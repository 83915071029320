/* Basscss Type Scale */

.h00 { font-size: var(--h00) }
.h0 { font-size: var(--h0) }
.h1 { font-size: var(--h1) }
.h2 { font-size: var(--h2) }
.h3 { font-size: var(--h3) }
.h4 { font-size: var(--h4) }
.h5 { font-size: var(--h5) }
.h6 { font-size: var(--h6) }

:root {
  --h00: 4rem;
  --h0: 3rem;
  --h1: 2rem;
  --h2: 1.5rem;
  --h3: 1.25rem;
  --h4: 1rem;
  --h5: .875rem;
  --h6: .75rem;
}
