._list { width: 160px; }

.bottom { top: 100%; }
.top { bottom: 100%; }
.left { right: 0; }
.right { left: 0; }

.topLeft { composes: _list top left; }
.topRight { composes: _list top right; }
.bottomLeft { composes: _list bottom left; }
.bottomRight { composes: _list bottom right; }

.item {
  font-size: 0.8rem;
}
