.root {
  padding-top: 0.5rem;
  font-size: 0.75rem;
  padding-bottom: 0.5rem;
  appearance: none;
  pointer-events: auto;
  line-height: 1.5;
  border-radius: 2px;
}

.wrapper {
  pointer-events: none;
  position: relative;
}

.wrapper:before {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  font-size: 0.8em;
  transform: translateY(-50%);
}

.root:focus,
.root:active,
.root:hover {
  outline: none;
}
