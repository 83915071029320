.col {
    white-space: wrap; 
    overflow: show;
  }

.row:focus {
  outline-color: auto;
}

.row:hover {
  border-top: 1px solid var(--primary);
  border-bottom: 1px solid var(--primary);
}