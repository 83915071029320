.common {}

.inactive {
  composes: common;
}

.active {
  composes: common;
  border-width: 8px;
}

.leftActive {
  composes: active;
  box-shadow: -4px 2px 5px rgba(0,0,0,0.1);
}

.rightActive {
  composes: active;
  box-shadow: 4px 2px 5px rgba(0,0,0,0.1);
}

.inactive:first-of-type,
.active + .inactive {
  border: none;
}
