.textarea,
.text,
.textarea:focus,
.textarea:active,
.text:focus,
.text:active {
  background: transparent;
  box-shadow: none;
  border: none;
  outline: none;
  padding-bottom: 0.2rem;
}

.textarea,
.textarea:focus,
.textarea:active {
  resize: none;
  font-size: 1rem;
}

.text,
.text:focus,
.text:active {
  box-shadow: 0 1px 0;
  padding-right: 1.5rem;
}

.edit {
  box-shadow: 0 1px transparent;
  padding-right: 1.5rem;
  transition: box-shadow 150ms ease-in-out;
  min-width: 0;
  padding-bottom: 0.2rem;
}

.edit:not(.nohover):hover {
  box-shadow: 0 1px 0;
}

.edit svg {
  right: 0;
  top: 0;
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}

.edit:not(.nohover):hover svg {
  opacity: 1;
}

.button {
  top: 0;
  right: -35px;

  width: 27px;
  height: 27px;

  color: var(--todo_ddd);
  font-size: 20px;
  line-height: 27px;
  text-align: center;

  border-radius: 3px;
}

.button:hover {
  color: var(--grayLight);
  background: rgba(0,0,0,0.05);
}
