.breadcrumb {
  &:first-child:hover {
    text-decoration: underline;
  }
  &:not(:first-child):hover {
    cursor: default;
  }
  &:not(:first-child) {
    pointer-events: auto;
  }
}

.reportText {
  text-transform: capitalize;
  font-size: 10px;
}

.reportText:hover {
  color: var(--grayLight);
}

.report {
  color: var(--white);
  padding:0;
}

.report:hover {
  color: var(--grayLight);
}
