.modal {}

:global(.ReactModal__Body--open) {
  & .nestedOverlay {
    background: rgba(255, 255, 255, 0.6);
  }

  & .overlay {
    background: rgba(0, 0, 0, 0.6);
  }

  & .nestedInner:before {
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.15);
  }

  & .inner:before {
    box-shadow: 0px 0px 110px var(--todo_2f2f2f);
  }
}

.baseOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  transition-delay: 150ms;
  transition: background 100ms ease-in;
}

.overlay {
  composes: baseOverlay;

  background: rgba(0, 0, 0, 0);
}

.baseInner {
  border-radius: 2;
  top: 47%;
  left: 50%;
  max-width: 90%;
  transform: translate(-50%, -47%);
  backface-visibility: hidden;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    transition-delay: 150ms;
    transition: box-shadow 50ms ease-in;
  }
}

.inner {
  composes: baseInner;

  position: fixed;

  &:before {
    box-shadow: 0px 0px 0px var(--todo_2f2f2f);
  }
}

.nestedInner {
  composes: baseInner;

  position: absolute;

  &:before {
    box-shadow: 0px 0px 0px rgba(0,0,0,0.15);
  }
}

.nestedOverlay {
  composes: baseOverlay;

  background: rgba(255, 255, 255, 0);
}
