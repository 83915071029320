@import './colors.css';

html {
  box-sizing: border-box;
  font-family: 'Verdana', sans-serif;
}

* + *, * > * {
  box-sizing: inherit;
  font-family: inherit;
}

html, body {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--grayLighter);
}

button {
  border: 0;
  border-radius: 0;
  background: transparent;
}

.styled-button {
  height: 40.5px;
  width: 204px;
  border: 1px solid #000;
  border-radius: 4px;
  background-color: #225cbb;
  color: #fff;
  text-align: center,;
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: bold;
  margin-bottom: 1rem
}

button:focus,
button:active {
  outline: none;
}

body.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.custom-scrollbar ::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,0.2);
}

.custom-scrollbar ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.custom-scrollbar ::-webkit-scrollbar-track {
  background: rgba(0,0,0,0.05);
}

.custom-scrollbar-inverted ::-webkit-scrollbar-thumb {
  border-radius: 999px;
  background: rgba(255,255,255,0.4);
}

.custom-scrollbar-inverted ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.custom-scrollbar-inverted ::-webkit-scrollbar-track {
  background: rgba(255,255,255,0.1);
}

a {
  cursor: pointer;
  text-decoration: none;
}

@-webkit-keyframes pulsate {
  0% {
    background-color: none;
  }
  50% {
    background-color: var(--primary);
  }
  100% {
    background-color: none;
  }
}

.img-responsive {
  width: 100%;
  display: block;
}

.userselect-none {
  user-select: none;
}

.userselect-text {
  user-select: text;
}

/* COLORS */
.transition-bg-color { transition: background-color 0.15s}

.bg-transparent, .bg-transparent-hover:hover, .bg-transparent-focus:focus, .bg-transparent-active:active { background: transparent; }
.bc-transparent { border-color: transparent; }

/* -- Primary */
.bg-primary, .bg-primary-hover:hover { background-color: var(--primary); }
.bg-primary-light, .bg-primary-light-hover:hover { background-color: var(--primaryLight); }
.bg-primary-lighter, .bg-primary-lighter-hover:hover { background-color: var(--primaryLighter); }
.bg-primary-transparent, .bg-primary-transparent-hover:hover { background-color: var(--primaryTransparent); }

.c-primary, .c-primary-hover:hover { color: var(--primary); }
.c-primary-light, .c-primary-light-hover:hover { color: var(--primaryLight); }
.c-primary-lighter, .c-primary-lighter-hover:hover { color: var(--primaryLighter); }

.fill-primary, .fill-primary-hover:hover { fill: var(--primary); }
.fill-primary-light, .fill-primary-light-hover:hover { fill: var(--primaryLight); }
.fill-primary-lighter, .fill-primary-lighter-hover:hover { fill: var(--primaryLighter); }

.bc-primary, .bc-primary-hover:hover { border-color: var(--primary); }
.bc-primary-light, .bc-primary-light-hover:hover { border-color: var(--primaryLight); }
.bc-primary-lighter, .bc-primary-lighter-hover:hover { border-color: var(--primaryLighter); }

/* -- Secondary */
.c-secondary, .c-secondary-hover:hover { color: var(--secondary); }
.c-secondary-light, .c-secondary-light-hover { color: var(--secondaryLight); }
.c-secondary-lighter, .c-secondary-lighter-hover:hover { color: var(--secondaryLighter); }

.bg-secondary, .bg-secondary-hover:hover { background-color: var(--secondary); }
.bg-secondary-light, .bg-secondary-light-hover:hover { background-color: var(--secondaryLight); }
.bg-secondary-lighter, .bg-secondary-lighter-hover:hover { background-color: var(--secondaryLighter); }

.bc-secondary, .bc-secondary-hover:hover, .bc-secondary-focus:focus { border-color: var(--secondary); }
.bc-secondary-light, .bc-secondary-light-hover:hover, .bc-secondary-light-focus:focus { border-color: var(--secondaryLight); }
.bc-secondary-lighter, .bc-secondary-lighter-hover:hover, .bc-secondary-lighter-focus:focus { border-color: var(--secondaryLighter); } */

/* -- Gray */
.bg-gray, .bg-gray-hover:hover { background-color: var(--gray); }
.bg-gray-dark, .bg-gray-dark-hover:hover { background-color: var(--grayDark); }
.bg-gray-light, .bg-gray-light-hover:hover { background-color: var(--grayLight); }
.bg-gray-lighter, .bg-gray-lighter-hover:hover { background-color: var(--grayLighter); }
.bg-gray-blue-dark, .bg-gray-blue-dark-hover:hover { background-color: var(--grayBlueDark); }
.bg-gray-blue-light, .bg-gray-blue-light-hover:hover { background-color: var(--grayBlueLight); }

.c-gray, .c-gray-hover:hover { color: var(--gray); }
.c-gray-accessible, .c-gray-accessible-hover:hover { color: var(--grayAccessible); }
.c-gray-dark, .c-gray-dark-hover:hover { color: var(--grayDark); }
.c-gray-light, .c-gray-light-hover:hover { color: var(--grayLight); }
.c-gray-lighter, .c-gray-lighter-hover:hover { color: var(--grayLighter); }

.bc-gray, .bc-gray-hover:hover { border-color: var(--gray); }
.bc-gray-accessible, .bc-gray-accessible-hover:hover { border-color: var(--grayAccessible); }
.bc-gray-dark, .bc-gray-dark-hover:hover { border-color: var(--grayDark); }
.bc-gray-light, .bc-gray-light-hover:hover { border-color: var(--grayLight); }
.bc-gray-lighter, .bc-gray-lighter-hover:hover { border-color: var(--grayLighter); }

.fill-gray, .fill-gray-hover:hover { fill: var(--gray); }
.fill-gray-dark, .fill-gray-dark-hover:hover { fill: var(--grayDark); }
.fill-gray-light, .fill-gray-light-hover:hover { fill: var(--grayLight); }
.fill-gray-lighter, .fill-gray-lighter-hover:hover { fill: var(--grayLighter); }

.c-gray-light-hover-trigger:hover .c-gray-light-hover-triggee {
  color: var(--grayLight);
}

/* -- Error */
.bg-error, .bg-error-hover:hover { background-color: var(--error); }
.c-error, .c-error-hover:hover { color: var(--error); }
.bc-error { border-color: var(--error); }

.bg-error-light { background-color: var(--errorLight); }
.c-error-light { color: var(--errorLight); }
.bc-error-light { border-color: var(--errorLight); }

.bg-error-lighter { background-color: var(--errorLighter); }
.bg-warn-lighter { background-color: var(--warnLighter); }

/* -- Success */
.bg-success { background-color: var(--success); }
.bg-success-dark { background-color: var(--successDark); }
.c-success { color: var(--success); }
.bc-success { border-color: var(--success); }


/* -- White */
.c-white, .c-white-hover:hover { color: var(--white); }
.c-white-10, .c-white-10-hover:hover { color: var(--white10); }
.c-white-25, .c-white-25-hover:hover { color: var(--white25); }
.c-white-40, .c-white-40-hover:hover { color: var(--white40); }
.c-white-55, .c-white-55-hover:hover { color: var(--white55); }
.c-white-70, .c-white-70-hover:hover { color: var(--white70); }
.c-white-85, .c-white-85-hover:hover { color: var(--white85); }

.fill-white, .fill-white-hover:hover { fill: var(--white); }
.fill-white-10, .fill-white-10-hover:hover { fill: var(--white10); }
.fill-white-25, .fill-white-25-hover:hover { fill: var(--white25); }
.fill-white-40, .fill-white-40-hover:hover { fill: var(--white40); }
.fill-white-55, .fill-white-55-hover:hover { fill: var(--white55); }
.fill-white-70, .fill-white-70-hover:hover { fill: var(--white70); }
.fill-white-85, .fill-white-85-hover:hover { fill: var(--white85); }

.bg-white, .bg-white-hover:hover { background-color: var(--white); }
.bg-white-10, .bg-white-10-hover:hover { background-color: var(--white10); }
.bg-white-25, .bg-white-25-hover:hover { background-color: var(--white25); }
.bg-white-40, .bg-white-40-hover:hover { background-color: var(--white40); }
.bg-white-55, .bg-white-55-hover:hover { background-color: var(--white55); }
.bg-white-70, .bg-white-70-hover:hover { background-color: var(--white70); }
.bg-white-85, .bg-white-85-hover:hover { background-color: var(--white85); }

.bc-white, .bc-white-hover:hover { border-color: var(--white); }
.bc-white-10, .bc-white-10-hover:hover { border-color: var(--white10); }
.bc-white-25, .bc-white-25-hover:hover { border-color: var(--white25); }
.bc-white-40, .bc-white-40-hover:hover { border-color: var(--white40); }
.bc-white-55, .bc-white-55-hover:hover { border-color: var(--white55); }
.bc-white-70, .bc-white-70-hover:hover { border-color: var(--white70); }
.bc-white-85, .bc-white-85-hover:hover { border-color: var(--white85); }

/* -- Black */
.bg-black, .bg-black-hover:hover { background-color: var(--black); }
.bg-black-10, .bg-black-10-hover:hover { background-color: var(--black10); }
.bg-black-25, .bg-black-25-hover:hover { background-color: var(--black25); }
.bg-black-40, .bg-black-40-hover:hover { background-color: var(--black40); }
.bg-black-55, .bg-black-55-hover:hover { background-color: var(--black55); }
.bg-black-70, .bg-black-70-hover:hover { background-color: var(--black70); }
.bg-black-85, .bg-black-85-hover:hover { background-color: var(--black85); }

.bc-black, .bc-black-hover:hover { border-color: var(--black); }
.bc-black-10, .bc-black-10-hover:hover { border-color: var(--black10); }
.bc-black-25, .bc-black-25-hover:hover { border-color: var(--black25); }
.bc-black-40, .bc-black-40-hover:hover { border-color: var(--black40); }
.bc-black-55, .bc-black-55-hover:hover { border-color: var(--black55); }
.bc-black-70, .bc-black-70-hover:hover { border-color: var(--black70); }
.bc-black-85, .bc-black-85-hover:hover { border-color: var(--black85); }

.c-black, .c-black-hover:hover { color: var(--black); }
.c-black-10, .c-black-10-hover:hover { color: var(--black10); }
.c-black-25, .c-black-25-hover:hover { color: var(--black25); }
.c-black-40, .c-black-40-hover:hover { color: var(--black40); }
.c-black-55, .c-black-55-hover:hover { color: var(--black55); }
.c-black-70, .c-black-70-hover:hover { color: var(--black70); }
.c-black-85, .c-black-85-hover:hover { color: var(--black85); }

.fill-black, .fill-black-hover:hover { fill: var(--black); }
.fill-black-10, .fill-black-10-hover:hover { fill: var(--black10); }
.fill-black-25, .fill-black-25-hover:hover { fill: var(--black25); }
.fill-black-40, .fill-black-40-hover:hover { fill: var(--black40); }
.fill-black-55, .fill-black-55-hover:hover { fill: var(--black55); }
.fill-black-70, .fill-black-70-hover:hover { fill: var(--black70); }
.fill-black-85, .fill-black-85-hover:hover { fill: var(--black85); }

/* Labels */
.label-primary,
.label-secondary,
.label-gray-light {
  border-radius: 8px;
  padding: 1px 4px;
  color: var(--grayDark);
}
.label-primary {
  background-color: var(--primary);
}
.label-secondary {
  background-color:  var(--secondary);
  color: var(--grayLighter);
}
.label-gray-light {
  background-color:  var(--grayLight);
}

/* Tags */
.tag {
  border-radius: 0.2rem;
  background-color: var(--grayLight);
  padding: 0.3rem 0.5rem;
  margin: 0.2rem;
  text-align: center;
  font-size: .75rem;
}

/* BORDERS */
.border-dashed { border-style: dashed }

/* Border radius */
.br-0 { border-radius: 0; }
.br-2, .br-small { border-radius: 2px; }
.br-big { border-radius: 4px; }
.br-8 { border-radius: 8px; }
.br-16 { border-radius: 16px; }

.brtl-2 { border-top-left-radius: 2px; }
.brtr-2 { border-top-right-radius: 2px; }
.brbl-2 { border-bottom-left-radius: 2px; }
.brbr-2 { border-bottom-right-radius: 2px; }

.bw-1 { border-width: 1px; }
.bw-2 { border-width: 2px; }
.bw-4 { border-width: 4px; }

/* Box Shadow (Outer border) */
.bs-primary { box-shadow: var(--primary) 0px 0px 0px 4px; }
.bs-secondary { box-shadow: var(--secondary) 0px 0px 0px 4px; }
.bs-gray { box-shadow: var(--gray) 0px 0px 0px 4px; }
.bst { box-shadow: transparent 0px 0px 0px 4px; }

/* Box Shadow Inset (Inner border) */
.bsi-primary { box-shadow: inset 0 0 0 2px var(--primary); }
.bsi-secondary { box-shadow: inset 0 0 0 2px var(--secondary); }
.bsi-gray { box-shadow: inset 0 0 0 2px var(--gray); }
.bst { box-shadow: 0 1px transparent; }

/* BG EFFECTS */
.bg-error-striped {
  background-image: repeating-linear-gradient( -45deg, rgba(255,255,255,0), rgba(255,255,255,0) 2px, rgba(191, 87, 88, 0.25) 2px, rgba(191, 87, 88, 0.25) 4px );
}

/* Paper */
.paper-1 { box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px; }
.paper-2 { box-shadow: rgba(0, 0, 0, 0.156863) 0px 3px 10px, rgba(0, 0, 0, 0.227451) 0px 3px 10px; }
.paper-3 { box-shadow: rgba(0, 0, 0, 0.188235) 0px 10px 30px, rgba(0, 0, 0, 0.227451) 0px 6px 10px; }
.paper-4 { box-shadow: rgba(0, 0, 0, 0.247059) 0px 14px 45px, rgba(0, 0, 0, 0.219608) 0px 10px 18px; }
.paper-5 { box-shadow: rgba(0, 0, 0, 0.298039) 0px 19px 60px, rgba(0, 0, 0, 0.219608) 0px 15px 20px; }

.drop-shadow { box-shadow: 0 2px 6px 0 rgba(89,89,89,0.62); }

:focus::-webkit-input-placeholder { color: transparent; }

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.muted { opacity: 0.5; }

.pointer-disabled {
  pointer-events: none;
}

.pointer-enabled {
  pointer-events: all;
}

.pointer-initial {
  pointer-events: initial;
}

.noselect {
  user-select: none;
}

/* Hovers */
.ease-in-out {
  transition: 50ms ease-in-out;
}

.scale {
  transition: transform 50ms linear;
  transform: initial;
}
.scale:hover { transform: scale(1.1,1.1); }

.pointer { cursor: pointer; }
.text-cursor { cursor: text;	}
.crosshair { cursor: crosshair; }
.initial-cursor { cursor: initial; }
.default-cursor { cursor: default; }

.grabbable {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.upAnimation {
  transition: transform 100ms;
}
.upAnimation:hover {
  transform: translateY(-2px);
}

.leftAnimation {
  transition: transform 100ms;
}
.leftAnimation:hover {
  transform: translateX(-2px);
}

.rightAnimation {
  transition: transform 100ms;
}
.rightAnimation:hover {
  transform: translateX(2px);
}

.goBack > * {
  transition: transform 200ms;
}

.goBack:hover > * {
  transform: translateX(-2px);
}

/* Visibility */
.hidden,
.visible-hover {
  display: none;
}

.visible-hover:hover,
.visible-hover-trigger:hover .visible-hover {
  display: block;
}

.opacity-hover-trigger .opacity-hover-triggee,
.opacity-hover {
  opacity: 0;
  transition: opacity 160ms linear;
}

.opacity-hover-trigger:hover .opacity-hover-triggee,
.opacity-hover:hover {
  opacity: 1;
}

.opacity-hover-lighter {
  opacity: 1;
  transition: opacity 50ms ease-in-out;
}

.opacity-hover-lighter:hover {
  opacity: 0.80;
}

.opacity-hover-lighter-performant {
  opacity: 1;
}

.opacity-hover-lighter-performant:hover {
  opacity: 0.80;
}

.opacity-hover-lighter-performant-alt {
  opacity: 1;
}

.opacity-hover-lighter-performant-alt:hover {
  opacity: 0.70;
}

.opacity-hover-parent .opacity-hover-child {
  opacity: 0;
}

.opacity-hover-parent:hover .opacity-hover-child {
  opacity: 1;
}

.invisible {
  visibility: hidden;
}

/* Inheritance size */
.max-height-inherit {
  max-height: inherit;
}

.max-width-inherit {
  max-width: inherit;
}

.max-inherit {
  max-height: inherit;
  max-width: inherit;
}

/* Overflow */
.overflow-hidden { overflow: hidden; }
.overflow-y-hidden { overflow-y: hidden; }
.overflow-x-hidden { overflow-x: hidden; }

.overflow-scroll { overflow: scroll; }
.overflow-y-scroll { overflow-y: scroll; }
.overflow-x-scroll { overflow-x: scroll; }

.overflow-overlay { overflow: overlay; }
.overflow-y-overlay { overflow-y: overlay; }
.overflow-x-overlay { overflow-x: overlay; }

.overflow-auto { overflow: auto; }
.overflow-y-auto { overflow-y: auto; }
.overflow-x-auto { overflow-x: auto; }

.overflow-anchor-none { overflow-anchor: none; }


.smooth-scroll { scroll-behavior: smooth; }

.zn1-hover:hover { z-index: -1; }
.z0-hover:hover { z-index: 0; }
.z1-hover:hover { z-index: 1; }
.z2-hover:hover { z-index: 2; }
.z3-hover:hover { z-index: 3; }
.z4-hover:hover { z-index: 4; }

/* Typography */
.f1 { font-size: 3rem; }
.f2 { font-size: 2.25rem; }
.f3 { font-size: 1.5rem; }
.f4 { font-size: 1.25rem; }
.f5 { font-size: 1rem; }
.f6 { font-size: .875rem; }
.f7 { font-size: .75rem; }
.f8 { font-size: .625rem; }

.uppercase { text-transform: uppercase; }
.capitalize { text-transform: capitalize; }
.lowercase { text-transform: lowercase; }

.border-first-none:first-of-type { border: none; }
.border-last-none:last-of-type { border: none; }
.border-none { border: none; }

.nowrap { white-space: nowrap; }
.break-word { word-wrap: break-word; }

.underline-hover:hover {
  text-decoration: underline;
}

.bold-hover:hover {
  font-weight: bold;
}

.line-height {
  line-height: 1.5em;
}

/* Psuedo class helpers */
.block-before:before {
  display: block !important;
}

/* Selections */
.select-disable {
  user-select: none;
}

.select-enable {
  user-select: all;
}

/* Pointer Events */
.events-disable {
  pointer-events: none;
}

.events-enable {
  pointer-events: auto;
}

.events-inherit {
  pointer-events: inherit;
}

/* Layout and Grid */

/* -- Positon */
.top-0 { top: 0; }
.right-0 { right: 0; }
.left-0 { left: 0; }
.bottom-0 { bottom: 0; }
.top-50 { top: 50%; }
.right-50 { right: 50%; }
.left-50 { left: 50%; }
.bottom-50 { bottom: 50%; }

/* Negative margin > see basscss.com for usage */
.mtn1 { margin-top: -0.5rem; }
.mtn2 { margin-top: -1rem; }
.mtn3 { margin-top: -2rem; }
.mtn4 { margin-top: -4rem; }

.mbn1 { margin-bottom: -0.5rem; }
.mbn2 { margin-bottom: -1rem; }
.mbn3 { margin-bottom: -2rem; }
.mbn4 { margin-bottom: -4rem; }

/* Margin auto - addition to basscss> see basscss.com for usage */
.m-auto {margin: auto;}
.my-auto {margin-top: auto; margin-bottom: auto;}

.flex-1 { flex: 1; }

.width-100 { width: 100%; }
.width-90 { width: 90%; }
.width-80 { width: 80%; }
.width-75 { width: 75%; }
.width-70 { width: 70%; }
.width-66 { width: calc((100% / 3) * 2); }
.width-60 { width: 60%; }
.width-50 { width: 50%; }
.width-40 { width: 40%; }
.width-33 { width: calc(100% / 3); }
.width-30 { width: 30%; }
.width-25 { width: 25%; }
.width-20 { width: 20%; }
.width-16 { width: calc(100% / 6); }
.width-12 { width: calc(100% / 8); }
.width-10 { width: 10%; }

.height-100 { height: 100%; }
.height-50 { height: 50%; }

/* X-Small Screens */
@media (min-width: 30em) {
  .xs-width-100 { width: 100%; }
  .xs-width-50 { width: 50%; }
  .xs-width-33 { width: calc(100% / 3); }
  .xs-width-25 { width: 25%; }
  .xs-width-20 { width: 20%; }
  .xs-width-16 { width: calc(100% / 6); }
  .xs-width-12 { width: calc(100% / 8); }
  .xs-width-10 { width: 10%; }

  .xs-col-1 { width: calc(100% / 12 * 1); }
  .xs-col-2 { width: calc(100% / 12 * 2); }
  .xs-col-3 { width: calc(100% / 12 * 3); }
  .xs-col-4 { width: calc(100% / 12 * 4); }
  .xs-col-5 { width: calc(100% / 12 * 5); }
  .xs-col-6 { width: calc(100% / 12 * 6); }
  .xs-col-7 { width: calc(100% / 12 * 7); }
  .xs-col-8 { width: calc(100% / 12 * 8); }
  .xs-col-9 { width: calc(100% / 12 * 9); }
  .xs-col-10 { width: calc(100% / 12 * 10); }
  .xs-col-11 { width: calc(100% / 12 * 11); }
  .xs-col-12 { width: calc(100% / 12 * 12); }

  .xs-p0 { padding: 0; }
}

/* Small Screens */
@media (min-width: 40em) {
  .sm-width-100 { width: 100%; }
  .sm-width-50 { width: 50%; }
  .sm-width-33 { width: calc(100% / 3); }
  .sm-width-25 { width: 25%; }
  .sm-width-20 { width: 20%; }
  .sm-width-16 { width: calc(100% / 6); }
  .sm-width-12 { width: calc(100% / 8); }
  .sm-width-10 { width: 10%; }
}

/* Medium Screens */
@media (min-width: 52em) {
  .md-width-100 { width: 100%; }
  .md-width-50 { width: 50%; }
  .md-width-33 { width: calc(100% / 3); }
  .md-width-25 { width: 25%; }
  .md-width-20 { width: 20%; }
  .md-width-16 { width: calc(100% / 6); }
  .md-width-12 { width: calc(100% / 8); }
  .md-width-10 { width: 10%; }
}

/* Large Screens */
@media (min-width: 64em) {
  .lg-width-100 { width: 100%; }
  .lg-width-50 { width: 50%; }
  .lg-width-33 { width: calc(100% / 3); }
  .lg-width-25 { width: 25%; }
  .lg-width-20 { width: 20%; }
  .lg-width-16 { width: calc(100% / 6); }
  .lg-width-12 { width: calc(100% / 8); }
  .lg-width-10 { width: 10%; }
}

/* X-Large Screens */
@media (min-width: 80em) {
  .xl-width-100 { width: 100%; }
  .xl-width-50 { width: 50%; }
  .xl-width-33 { width: calc(100% / 3); }
  .xl-width-25 { width: 25%; }
  .xl-width-20 { width: 20%; }
  .xl-width-16 { width: calc(100% / 6); }
  .xl-width-12 { width: calc(100% / 8); }
  .xl-width-10 { width: 10%; }

  .xl-col-1 { width: calc(100% / 12 * 1); }
  .xl-col-2 { width: calc(100% / 12 * 2); }
  .xl-col-3 { width: calc(100% / 12 * 3); }
  .xl-col-4 { width: calc(100% / 12 * 4); }
  .xl-col-5 { width: calc(100% / 12 * 5); }
  .xl-col-6 { width: calc(100% / 12 * 6); }
  .xl-col-7 { width: calc(100% / 12 * 7); }
  .xl-col-8 { width: calc(100% / 12 * 8); }
  .xl-col-9 { width: calc(100% / 12 * 9); }
  .xl-col-10 { width: calc(100% / 12 * 10); }
  .xl-col-11 { width: calc(100% / 12 * 11); }
  .xl-col-12 { width: calc(100% / 12 * 12); }
}

/* XX-Large Screens */
@media (min-width: 120em) {
  .xxl-width-100 { width: 100%; }
  .xxl-width-50 { width: 50%; }
  .xxl-width-33 { width: calc(100% / 3); }
  .xxl-width-25 { width: 25%; }
  .xxl-width-20 { width: 20%; }
  .xxl-width-16 { width: calc(100% / 6); }
  .xxl-width-12 { width: calc(100% / 8); }
  .xxl-width-10 { width: 10%; }

  .xxl-col-1 { width: calc(100% / 12 * 1); }
  .xxl-col-2 { width: calc(100% / 12 * 2); }
  .xxl-col-3 { width: calc(100% / 12 * 3); }
  .xxl-col-4 { width: calc(100% / 12 * 4); }
  .xxl-col-5 { width: calc(100% / 12 * 5); }
  .xxl-col-6 { width: calc(100% / 12 * 6); }
  .xxl-col-7 { width: calc(100% / 12 * 7); }
  .xxl-col-8 { width: calc(100% / 12 * 8); }
  .xxl-col-9 { width: calc(100% / 12 * 9); }
  .xxl-col-10 { width: calc(100% / 12 * 10); }
  .xxl-col-11 { width: calc(100% / 12 * 11); }
  .xxl-col-12 { width: calc(100% / 12 * 12); }
}

@keyframes progress-bar-stripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 1rem 0;
  }
}

/* React virtualized dependencies, TODO: remove when webpack */
/* Table default theme */
.ReactVirtualized__Table__headerRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ReactVirtualized__Table__row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ReactVirtualized__Table__headerTruncatedText {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
  margin-right: 10px;
  min-width: 0px;
}
.ReactVirtualized__Table__rowColumn {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ReactVirtualized__Table__headerColumn:first-of-type,
.ReactVirtualized__Table__rowColumn:first-of-type {
  margin-left: 10px;
}
.ReactVirtualized__Table__sortableHeaderColumn {
  cursor: pointer;
}

.ReactVirtualized__Table__sortableHeaderIconContainer {
  display: flex;
  align-items: center;
}
.ReactVirtualized__Table__sortableHeaderIcon {
  flex: 0 0 24px;
  height: 1em;
  width: 1em;
  fill: currentColor;
}

/* Annotations */

.annotation-closed:hover {
  background: var(--secondary) !important;
}

/* Misc */

@media screen and (max-width: 1300px) {
  .hide-when-small {
    display: none;
  }
}
