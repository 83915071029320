.title {
  width: calc(100% - 22px);
  background: var(--white);
  color: var(--grayDark);
  border-radius: 0 0 0 2px;
}

.menuList {
  border-radius: 2px 0 0 0;
}

.image {
  border-top: none;
  overflow: hidden;
}
