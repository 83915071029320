.row:focus {
  outline-color: auto;
}

.row:hover {
  border-top: 1px solid var(--primary);
  border-bottom: 1px solid var(--primary);
}

.rowSelected {
  composes: row;
  background: var(--secondaryLighter);
}

.focusableElement:focus {
  outline-color: auto;
}

.baseAnimation {
  transition: transform 100ms;
}

.upAnimation {
  composes: baseAnimation;
  &:hover {
    transform: translateY(-2px);
  }
}