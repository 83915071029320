.polygon {
  fill: var(--todo_eee);
  pointer-events: visible;
  transition: all 50ms ease-in-out;
}

.polygon:hover, .floor:hover {
  fill: var(--white);
  cursor: pointer;
}
.polygon:hover:active {
  fill: var(--primary);
}

.selected {
  fill: var(--todo_aaa);
}

.line {
  stroke-width: 0.75;
  stroke: var(--grayDark);
}

.dashedLine {
  stroke-width: 0.75;
  stroke: var(--todo_8c8c8c);
}

.disabled {
  stroke: var(--grayLight);
}

.selected:hover {
  fill: var(--grayLight);
}

.svg {
  border: 1px solid var(--grayDark);
}
.svgDisabled {
  border: 1px solid var(--grayLight);
}
